





























































import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import MonoguseApp from '@/mixins/monoguse/MonoguseApp'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
  },
})
export default class AssistMaterial extends Mixins(ClassModeChangeAble, LessonStudent, MonoguseApp) {
  private isSozo = Vue.prototype.$gdlsCookiesV3?.isSozo()
  private isV3 = Vue.prototype.$gdlsCookiesV3?.isV3()

  private academyCode = ''

  private noticeCurrentPageText = '補助教材選択中'

  private noticePageTitle = ''

  private get classMode() {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private get branchId() {
    return Vue.prototype.$cookies.get('dataGdls').branchId
  }

  // TODO とりあえずは全教研専用コースのみを考慮するが他の塾専用サービスも開始される場合は拡張が必要
  private get isZenkyoken() {
    return this.academyCode === this.PRIVATE_SERVICE_ACADEMY_CODE
  }

  public async mounted() {
    // cookieのクラスモードが補助教材以外の場合は補助教材モードへ更新
    if (this.classMode != this.CLASS_MODE.ASSIST) this.changeClassMode(this.CLASS_MODE.ASSIST)

    await this.loadAcademyCode()

    this.noticeGroup()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    console.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  /**
   * 教科選択画面URL取得
   * @params assistMaterialCode 一問一答ドリル: OO, 診断テスト: ST, 復習テスト: HS, 補助動画: HD
   * @params serviceCode 全教研: ZK
   */
  private async transitionSelectSubject(assistMaterialCode: string, serviceCode?: string) {
    // cookieにserviceCodeを追加
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.serviceCode = serviceCode || ''
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    this.$router.push(`/student/assist-material/subjects/${assistMaterialCode}`)
  }

  /**
   * 塾コードを取得する
   */
  private async loadAcademyCode() {
    if (this.branchId) {
      await Vue.prototype.$http.httpWithToken.get(`/branches/${this.branchId}/academyCode`).then((res: any) => {
        this.academyCode = res.data.code
      })
    }
  }
}
