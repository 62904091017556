import { render, staticRenderFns } from "./AssistMaterial.vue?vue&type=template&id=8c9d1cb4&scoped=true&lang=pug&"
import script from "./AssistMaterial.vue?vue&type=script&lang=ts&"
export * from "./AssistMaterial.vue?vue&type=script&lang=ts&"
import style0 from "./AssistMaterial.vue?vue&type=style&index=0&id=8c9d1cb4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c9d1cb4",
  null
  
)

export default component.exports